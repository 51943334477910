import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'absen',
    loadChildren: () => import('./pages/absen/absen.module').then( m => m.AbsenPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'berita',
    loadChildren: () => import('./pages/berita/berita.module').then( m => m.BeritaPageModule)
  },
  {
    path: 'suratmasuk',
    loadChildren: () => import('./pages/suratmasuk/suratmasuk.module').then( m => m.SuratmasukPageModule)
  },
  {
    path: 'suratdetail',
    loadChildren: () => import('./pages/suratdetail/suratdetail.module').then( m => m.SuratdetailPageModule)
  },
  {
    path: 'profil',
    loadChildren: () => import('./pages/profil/profil.module').then( m => m.ProfilPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
