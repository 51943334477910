import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Storage} from '@ionic/storage-angular';
import {Platform} from "@ionic/angular";
import OneSignal from 'onesignal-cordova-plugin';

@Injectable({
  providedIn: 'root'
})
export class EofficeService {
  url = 'https://services.riau.go.id/api/v1/';

  public dataLogin : any;
  token:any;
  public playerId : string = null;

  constructor(
    private http: HttpClient,
    private storage : Storage,
    private platform: Platform,
  )
  {
    this.getDataLogin();
  }

  async setDataLogin(data)
  {
    this.token = data['token'];
    this.dataLogin = data;
  }

  async getDataLogin() {
    return this.storage.get('session_login').then((res) => {
      if(res){
        this.token = res['token'];
        this.dataLogin = res;
      }else{
        this.token = null;
        this.dataLogin = null;
      }
    });
  }

  getLoginUser(postData : any)
  {
    const headers = new HttpHeaders();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/x-www-form-urlencoded' );
    const service = 'login';
    const options = { headers: headers };
    return this.http.post(this.url+service, postData, options);
  }

  logout(postData:any)
  {
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization' : 'Bearer '+this.token
    });
    const service = 'logout';
    const options = { headers: headers };

    return this.http.post(this.url+service,postData, options);
  }

  getMenu()
  {
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Content-Type' : 'application/x-www-form-urlencoded',
      'Authorization' : 'Bearer '+this.token
    });
    const options = { headers: headers };
    const service = 'beranda/menu';
    return this.http.get<any[]>(this.url+service, options);
  }

  getBerita()
  {
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Content-Type' : 'application/x-www-form-urlencoded',
      'Authorization' : 'Bearer '+this.token
    });
    const service = 'beranda/berita/5';
    const options = { headers: headers };
    return this.http.get<any[]>(this.url+service, options);
  }

  getAbsenHarian(nip : string, tanggal : string)
  {
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Content-Type' : 'application/x-www-form-urlencoded',
      'Authorization' : 'Bearer '+this.token
    });
    const service = 'absen/riwayat/harian/nip/'+nip+'/'+tanggal;
    const options = { headers: headers };
    return this.http.get<any[]>(this.url+service, options);
  }

  getAbsenBulanan(nip : string, tanggal : string)
  {
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Content-Type' : 'application/x-www-form-urlencoded',
      'Authorization' : 'Bearer '+this.token
    });
    const service = 'absen/riwayat/bulanan-order/nip/'+nip+'/'+tanggal;
    const options = { headers: headers };
    return this.http.get<any[]>(this.url+service, options);
  }

  getKoordinatAbsen(opd:any)
  {
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Content-Type' : 'application/x-www-form-urlencoded',
      'Authorization' : 'Bearer '+this.token
    });
    const options = { headers: headers };
    const service = 'absen/koordinat/skpd/'+opd;
    return this.http.get<any[]>(this.url+service, options);
  }

  storeAbsen(postData:any)
  {
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization' : 'Bearer '+this.token
    });
    const service = 'absen/store/checkinout';
    const options = { headers: headers };

    return this.http.post(this.url+service,postData, options);
  }

  getOneSignal() {
    if (!this.platform.is('mobileweb')) {
      OneSignal.getDeviceState((e) => {
        this.playerId = e.userId;
      })
    }
  }

  getDraftCheckinout(nip,page,limit)
  {
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Content-Type' : 'application/x-www-form-urlencoded',
      'Authorization' : 'Bearer '+this.token
    });
    
    const service = 'absen/draft/checkinout/'+nip+'?halaman='+page+'&per_halaman='+limit;
    const options = { headers: headers };
    return this.http.get(this.url+service, options);
  }

  getDetailDraftCheckinout(id)
  {
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Content-Type' : 'application/x-www-form-urlencoded',
      'Authorization' : 'Bearer '+this.token
    });

    const service = 'absen/draft/checkinout/detail/'+id;
    const options = { headers: headers };
    return this.http.get<any[]>(this.url+service, options);
  }

  storeDraftCheckinout(postData:any)
  {
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization' : 'Bearer '+this.token
    });
    const service = 'absen/store/draft/checkinout';
    const options = { headers: headers };

    return this.http.post(this.url+service,postData, options);
  }
  getSuratMasuk(kantor_id, pegawai_id,page,statusSurat)
  {
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Content-Type' : 'application/x-www-form-urlencoded',
      'Authorization' : 'Bearer '+this.token
    });
    const service = 'eoffice/surat-masuk/'+kantor_id+'/'+pegawai_id+'?halaman='+page+'&per_halaman=10&order_status_penerima='+statusSurat;
    const options = { headers: headers };
    return this.http.get(this.url+service,options);    
  }

  getDetailPegawai(nip)
  {
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Content-Type' : 'application/x-www-form-urlencoded',
      'Authorization' : 'Bearer '+this.token
    });
    const service = 'eoffice/pegawai/nip/'+nip;
    const options = { headers: headers };
    return this.http.get<any[]>(this.url+service, options);
  }
  updateStatusSuratMasuk(suratpenerima_id)
  {
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization' : 'Bearer '+this.token
    });
    const service = 'eoffice/update-status-surat-masuk';
    const options = { headers: headers };
    let postData = {
      surat_penerima_id:suratpenerima_id,
    };
    return this.http.post(this.url+service,postData, options);
  }

  getCariSurat(kantor_id, pegawai_id,page, val)
  {
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Content-Type' : 'application/x-www-form-urlencoded',
      'Authorization' : 'Bearer '+this.token
    });
    const service = 'eoffice/surat-masuk/'+kantor_id+'/'+pegawai_id+'?halaman='+page+'&per_halaman=20&perihal='+val;
    const options = { headers: headers };
    return this.http.get(this.url+service,options);    
  }

  getFilterSurat(kantor_id, pegawai_id,page, statusSurat)
  {
    const headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Content-Type' : 'application/x-www-form-urlencoded',
      'Authorization' : 'Bearer '+this.token
    });
    const service = 'eoffice/surat-masuk/'+kantor_id+'/'+pegawai_id+'?halaman='+page+'&per_halaman=10&order_status_penerima='+statusSurat;
    const options = { headers: headers };
    return this.http.get(this.url+service,options);    
  }
}
