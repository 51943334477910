import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { EofficeService } from './shared/api/eoffice.service';

import { Camera } from '@ionic-native/camera/ngx';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';

import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {OneSignal} from "@ionic-native/onesignal/ngx";
import { FileOpener } from '@ionic-native/file-opener/ngx';

import { BackgroundGeolocation } from '@awesome-cordova-plugins/background-geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, HttpClientModule, IonicModule.forRoot(),
    AppRoutingModule, IonicStorageModule.forRoot(), FormsModule, ReactiveFormsModule
  ],
  providers: [
    BackgroundGeolocation,BarcodeScanner, Geolocation,NativeGeocoder, EofficeService, FileTransfer, FileTransferObject, File, FilePath, Camera, OneSignal,FileOpener,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
