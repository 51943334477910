import {Location} from '@angular/common';
import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AlertController, Platform} from '@ionic/angular';
import {Storage} from '@ionic/storage-angular';
import OneSignal from 'onesignal-cordova-plugin';
import { ToastController } from '@ionic/angular';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public nextReq: boolean = false;
  public dataUser: any = [];

  constructor(
    public storage: Storage,
    public router: Router,
    private platform: Platform,
    private _location: Location,
    private alertController: AlertController,
    public toastController: ToastController
    // private oneSignal: OneSignal
  ) {
    this.storage.create();
    this.OneSignalInit();
    this.backExitButton();
    this.storage.get('session_login').then((res) => {
      if (res) {
        this.dataUser = res;
        this.nextReq = true;
      } else {
        this.initializeApp();
        // this.router.navigate(['login']);
      }
    });
    
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.router.navigateByUrl('splash');
    });

  }

  backExitButton(){
    
    let klikback = 1;
    this.platform.backButton.subscribeWithPriority(100, (processNextHandler) => {
      console.log('Back press handler!');
      if (this._location.isCurrentPathEqualTo('/home')) {
        // Show Exit Alert!
        console.log('Show Exit Alert!');
        if(klikback==2){
          navigator['app'].exitApp();
          processNextHandler();
        }else{
          this.presentToast('Ketuk lagi untuk keluar E-Office')
        }
        klikback++;
        setTimeout(()=>{                           //<<<---using ()=> syntax
          klikback=1;
        }, 1000);
      } else {
        // Navigate to back page
        console.log('Navigate to back page');
        this._location.back();
      }
    });
  }
  async presentToast(pesan) {
    const toast = await this.toastController.create({
      message: pesan,
      duration: 2000
    });
    toast.present();
  }

  showExitConfirm() {
    this.alertController.create({
      header: 'App termination',
      message: 'Do you want to close the app?',
      backdropDismiss: false,
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }

  OneSignalInit(): void {
    if (!this.platform.is('mobileweb')) {
      OneSignal.setAppId("d742706c-4eb3-43e3-afa1-341d1d7414c6");
      OneSignal.setNotificationOpenedHandler((result) => {
        this.router.navigate([result.notification.rawPayload['actionId']]);
      });
    }
  }
}
